import React, { useState } from "react";
import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col
} from "react-bootstrap";

function UserProfileForm({ closeModal, edit, userDetails = {} }) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    

    const passwordsMatch = password === confirmPassword;

    const isUpdating = Object.keys(userDetails).length > 0;
    const passwordRequired = !isUpdating || (isUpdating && password);
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData(e.target);
        const formObject = {};
        formData.forEach((value, key) => {
            formObject[key] = value;
        });
    
        const isUpdating = Boolean(userDetails && userDetails.id);
        const url = isUpdating ? 'https://webex.l1x.foundation/updateuser' : 'https://webex.l1x.foundation/adduser';
        
        if (isUpdating) {
            formObject.id = userDetails.id; // Add user ID to the payload when updating
            if (!password) delete formObject.password;
        }
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formObject)
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // console.log("Success:", data);
                window.location.reload();
            } else if (response.status === 500) {
                setErrorMessage("Operation failed! Please contact your administration for help!");
            } else if (data.detail && data.detail === "user email already exists") {
                setErrorMessage("User email already exists! Please check the email field and try again!");
            } else {
                console.error("Error:", data);
            }
    
        } catch (error) {
            console.error("There was an error!", error);
        }
    }
    

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h4">{edit ? "Edit User" : "Add New User"}</Card.Title>
                </Card.Header>

                {errorMessage && (
                    <div style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>
                        {errorMessage}
                    </div>
                )}
                <Form action="/" className="form-horizontal" onSubmit={handleFormSubmit}>
                    <Card.Body>
                        <Card.Body>
                            <Row>
                                <Col className="control-label" sm="2">
                                    Firstname
                                </Col>
                                <Col sm="3">
                                    <Form.Group>
                                        <Form.Control
                                            name="firstname"
                                            type="text"
                                            required
                                            defaultValue={userDetails?.firstname || ''}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="control-label" sm="3">
                                    Last Name
                                </Col>
                                <Col sm="3">
                                    <Form.Group>
                                        <Form.Control
                                            name="lastname"
                                            type="text"
                                            required
                                            defaultValue={userDetails?.lastname || ''}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="control-label" sm="2">
                                    Email
                                </Col>
                                <Col sm="9">
                                    <Form.Group>
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            required
                                            defaultValue={userDetails?.email || ''}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="control-label" sm="2">
                                    Password
                                </Col>
                                <Col sm="3">
                                    <Form.Group>
                                        <Form.Control
                                            name="password"
                                            type="password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            required={passwordRequired}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col className="control-label" sm="3">
                                    Confirm Password
                                </Col>
                                <Col sm="3">
                                    <Form.Group>
                                        <Form.Control
                                            type="password"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            style={{
                                                borderColor: passwordsMatch ? null : 'red'
                                            }}
                                            required={passwordRequired}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>

                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col sm="6" className="d-flex justify-content-center align-items-center">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn-wd btn-outline mr-1 btn btn-primary">
                                    <span className="px-2">{isUpdating ? "Update" : "Submit"}</span>
                                </Button>
                            </Col>
                            <Col sm="6" className="d-flex justify-content-center align-items-center">
                                <Button
                                    color="danger"
                                    className="btn-wd btn-outline mr-1 btn btn-danger"
                                    onClick={closeModal}>
                                    <span className="px-2">Cancel</span>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Form>
            </Card>
        </>
    );
}

export default UserProfileForm;
