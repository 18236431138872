import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import Papa from 'papaparse';
import {
  CardHeader,
  Button,
  UncontrolledTooltip
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactMarkdown from 'react-markdown';
const API_BASE_URL = process.env.API_ENDPOINT_URL_PREFIX;


export default function PageChat() {
  
  const [chatData, setChatData] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filename = params.get('filename');

  const fetchCSVContent = async () => {
    try {
      const response = await fetch(`https://webex.l1x.foundation/getFileContent?filename=${filename}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();  // Parse the response as JSON
      return responseData.content;  // Return the content value
    } catch (error) {
      console.error("There was a problem fetching the CSV content:", error.message);
      return '';
    }
  };
  

  useEffect(() => {
    fetchCSVContent().then(csvContent => {
      Papa.parse(csvContent, {
        header: true,
        complete: (result) => {
          const messages = result.data.reduce((acc, item) => {
            if (!item['message content'] || !item.sender || !item.time) {
              return acc;
            }

            const lastMessage = acc[acc.length - 1];
            if (!lastMessage || lastMessage.sender !== item.sender || lastMessage.time !== item.time) {
              acc.push({
                sender: item.sender,
                'message content': [item['message content']],
                time: item.time
              });
            } else {
              lastMessage['message content'].push(item['message content']);
            }

            return acc;
          }, []);
          
          setChatData(messages);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        }
      });
    });

  }, [filename]);

 // Find the index of the first underscore
 const firstUnderscoreIndex = filename.indexOf('_');

 // Extract space_id
 const space_id = filename.substring(0, firstUnderscoreIndex);

 // Extract and format date
 const dateTimePart = filename.substring(firstUnderscoreIndex + 1);
 const date = dateTimePart.substring(0, 8);
 const formattedDate = `${date.slice(6, 8)}/${date.slice(4, 6)}/${date.slice(0, 4)}`;

 // Extract and format time
 const time = dateTimePart.substring(9);
 const formattedTime = `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4, 6)}`;


  return (
    <div className="app-inner-content-layout">
      <div className="app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary">
        <CardHeader className="rounded-0 bg-white p-4 border-bottom">
          <div className="card-header--title">
            <small>Chat history</small>
            <b>{space_id}</b>
            <small>Uploaded @  {formattedDate} - {formattedTime}</small>
          </div>
          <div className="card-header--actions">
            <a href="/admin/chats">
              <Button className="btn-nr btn-outline mr-1" type="button" variant="info" id='back-btn'>
                <span className="btn-label">
                  <i className="fas fa-arrow-left"></i>
                  Back
                </span>
              </Button>
            </a>
            <UncontrolledTooltip target="back-btn">Back to the list</UncontrolledTooltip>
          </div>
        </CardHeader>
        <PerfectScrollbar>
          <div className="chat-wrapper p-3">
            {chatData.map((item, index) => (
              <div className="chat-item p-2 mb-2" key={index}>
                <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
                  <div className="avatar-icon rounded-circle shadow-none">{item.sender}</div>
                </div>
                {/* <div className="align-box-row"> */}
                <div>
                  <div className="chat-box bg-gray-400 text-second">
                    {item['message content'].map((message, messageIndex) => (
                      <ReactMarkdown key={messageIndex} children={message} />
                    ))}
                  </div>
                  <small className="mt-2 d-block text-black-50">
                    <FontAwesomeIcon icon={faClock} className="mr-1 opacity-5" />
                    {item.time}
                  </small>
                </div>
              </div>
            ))}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
}
