
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "./assets/css/demo.css";

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import Login from "./views/Pages/LoginPage";
import PrivateRoute from "./security/PrivateRoute";
import { UserProvider } from "./security/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <BrowserRouter>
//     <Switch>
//       {/* <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> */}
//       <Route path="/login" render={(props) => <Login {...props} />} />
//       <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
//       <Redirect from="/" to="/admin/chats" />
//     </Switch>
//   </BrowserRouter>
// );


root.render(
  <UserProvider>
    <BrowserRouter>
      <Switch>
        {/* <PrivateRoute path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Redirect from="/" to="/admin/chats" />
      </Switch>
    </BrowserRouter>
  </UserProvider>
);

