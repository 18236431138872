import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from './UserContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useUser();
    console.log("Is Logged In:", isLoggedIn);


    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? React.createElement(Component, props) : <Redirect to="/login" />
            }
            
            
        />
    );
}

export default PrivateRoute;
