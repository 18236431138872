import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Container,
  Col,
  Row
} from "react-bootstrap";
import companyLogoURL from "../../assets/img/l1x.svg";
import { useUser } from "../../security/UserContext";
import { useHistory } from "react-router-dom";


function LoginPage() {
  const [cardClasses, setCardClasses] = useState("card-hidden");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberEmail, setRememberEmail] = useState(false);
  const [loginError, setLoginError] = useState("");
  const history = useHistory();


  const { login } = useUser();

  useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 200);

    const storedEmail = localStorage.getItem("storedEmail");
    if (storedEmail) {
      setEmail(storedEmail);
      setRememberEmail(true);  // Set the rememberEmail state to true if an email is stored
    } else {
      setRememberEmail(false); // Set the rememberEmail state to false if no email is stored
    }
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (rememberEmail) {
      localStorage.setItem("storedEmail", email);
    } else {
      localStorage.removeItem("storedEmail");
    }

    const response = await fetch('https://webex.l1x.foundation/login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      const data = await response.json();
      login(data.access_token,data.firstname, data.email);
      setLoginError("");  // Clear any previous errors
      history.push("/admin/chats");
    } else {
      setLoginError("Login failed! Please enter the right combination of email and password.");
    }
  }

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("../../assets/img/full-screen-image-2.jpg")}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Row>
              <Col xs={12} className="text-center mb-2">
                <img src={companyLogoURL} alt="Company Logo" width="50" className="mr-4" />
                <h3 style={{ color: 'white', display: 'inline' }}>Layer One X</h3>
              </Col>

              <Col xs={12} className="text-center mb-4">
                <h2 style={{ color: 'white' }}>Webex Chat Visualizer</h2>
              </Col>

              <Col xs={12} lg={4} className="mx-auto">
                <Form action="" className="form" method="" onSubmit={handleLogin}>
                  <Card className={"card-login " + cardClasses}>
                    <Card.Header>
                      <h3 className="header text-center">Login</h3>
                      {loginError && (
                        <div style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>
                          {loginError}
                        </div>
                      )}
                    </Card.Header>
                    <Card.Body>
                      <Card.Body>
                        <Form.Group>
                          <label>Email address</label>
                          <Form.Control
                            placeholder="Enter email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Password</label>
                          <Form.Control
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Check className="pl-0">
                          <Form.Check.Label>
                            <Form.Check.Input
                              checked={rememberEmail}
                              type="checkbox"
                              onChange={() => setRememberEmail(!rememberEmail)}
                            ></Form.Check.Input>
                            <span className="form-check-sign"></span>
                            Remember Email
                          </Form.Check.Label>
                        </Form.Check>
                      </Card.Body>
                    </Card.Body>
                    <Card.Footer className="ml-auto mr-auto">
                      <Button className="btn-wd" type="submit" variant="warning">
                        Login
                      </Button>
                    </Card.Footer>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/full-screen-image-2.jpg") + ")"
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;
