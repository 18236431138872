
// import Dashboard from "./views/Dashboard.js";
import Chat from "./views/Private/Chat.js";
import ChatList from "./views/Private/ChatList.js";
// import Buttons from "./views/Components/Buttons.js";
// import GridSystem from "./views/Components/GridSystem.js";
// import Panels from "./views/Components/Panels.js";
// import SweetAlert from "./views/Components/SweetAlertPage.js";
// import Notifications from "./views/Components/Notifications.js";
// import Icons from "./views/Components/Icons.js";
// import Typography from "./views/Components/Typography.js";
// import RegularForms from "./views/Forms/RegularForms.js";
// import ExtendedForms from "./views/Forms/ExtendedForms.js";
// import ValidationForms from "./views/Forms/ValidationForms.js";
// import Wizard from "./views/Forms/Wizard/Wizard.js";
// import RegularTables from "./views/Tables/RegularTables.js";
// import ExtendedTables from "./views/Tables/ExtendedTables.js";
// import ReactTables from "./views/Tables/ReactTables.js";
// import GoogleMaps from "./views/Maps/GoogleMaps.js";
// import FullScreenMap from "./views/Maps/FullScreenMap.js";
// import VectorMap from "./views/Maps/VectorMap.js";
// import Charts from "./views/Charts.js";
// import Calendar from "./views/Calendar.js";
import UserPage from "./views/Pages/UserPage.js";
// import LoginPage from "./views/Pages/LoginPage.js";
// import RegisterPage from "./views/Pages/RegisterPage.js";
// import LockScreenPage from "./views/Pages/LockScreenPage.js";
import Usermgmt from "./views/Private/UserMgmt.js";

var routes = [
  {
    path: "/chats",
    layout: "/admin",
    name: "Chats",
    icon: "nc-icon nc-align-left-2",
    component: ChatList
  },
  {
    path: "/usermgmt",
    layout: "/admin",
    name: "User Management",
    icon: "nc-icon nc-single-02",
    component: Usermgmt
  },
  {
    path: "/chat",
    layout: "/admin",
    redirect: "false",
    component: Chat
  }
  
];
export default routes;
