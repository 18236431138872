import React from 'react';

import Chat from '../../components/Private/PageChat';

import { Container, Row } from "react-bootstrap";
export default function PageChat() {
    return (
        <>
            <Container >
                <Row>
                    <Chat />
                </Row>
            </Container>
        </>
    );
}
