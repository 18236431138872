import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Form, InputGroup, Navbar, Nav, Table, Container, Row, Col } from "react-bootstrap";
import ReactTable from "../../components/ReactTable/ReactTable.js";
import FormModal from "../Components/FormModal.js";
import UserProfileModal from "../Components/UserProfileModal.js";
import UserProfileForm from "../Components/UserProfileForm.js";
import {
  Modal,
} from 'reactstrap';
import { useUser } from "../../security/UserContext";

function UserMgmt() {
  const [data, setData] = useState([]);
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [userDetails, setUserDetails] = useState({});
  const { loggedInUser } = useUser();


  const handleEdit = async (userId) => {
    try {
      const response = await fetch(`https://webex.l1x.foundation/getuser?id=${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const user = await response.json();
      setUserDetails(user); // Set the state
      toggle1(); // Open the modal
    } catch (error) {
      console.error("There was a problem with fetching user details:", error.message);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(`https://webex.l1x.foundation/deleteuser?id=${userId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      window.location.reload();
    } catch (error) {
      console.error("There was a problem with the delete operation:", error.message);
    }
  };

  useEffect(() => {
    fetch("https://webex.l1x.foundation//listusers")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((apiData) => {
        const formattedData = apiData.map((prop) => {
        const isCurrentUser = loggedInUser.email === prop.email;
          return {
            id: prop.id,
            firstname: prop.firstname,
            lastname: prop.lastname,
            email: prop.email,
            actions: (
              <div className="actions-right">

                  <Button variant="info" size="sm" className="text-info btn-link" onClick={() => handleEdit(prop.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  </Button>{" "}
                <Button
                  onClick={() => handleDelete(prop.id)}
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                  disabled={isCurrentUser}
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
        });
        setData(formattedData);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error.message);
      });
  }, []);

  return (
    <>
      <Modal zIndex={2000} centered isOpen={modal1} toggle={toggle1} size="lg">
        <UserProfileForm closeModal={toggle1} edit={true} userDetails={userDetails}/>
      </Modal>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" className="float-left">User Management</Card.Title>
                <UserProfileModal />
              </Card.Header>
              <div className="border mt-3"></div>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "First Name",
                      accessor: "firstname",
                    },
                    {
                      Header: "Last Name",
                      accessor: "lastname",
                    },
                    {
                      Header: "Email Address",
                      accessor: "email",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserMgmt;
