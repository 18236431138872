import React, { useState } from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Card,
    InputGroupText,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import UserProfileForm from "./UserProfileForm.js";

export default function UserProfileModal() {
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);

    return (
        <>
            <Button
                color="primary"
                // className="hover-scale-sm font-weight-bold btn-pill px-4"
                className="btn-wd btn-outline mr-1 float-right"
                type="button"
                onClick={toggle1}
            >
                <span className="btn-label">
                    <i className="bi bi-person mr-1 "></i>
                    Add User
                </span>

            </Button>
            <Modal zIndex={2000} centered isOpen={modal1} toggle={toggle1} size="lg">
                <UserProfileForm closeModal={toggle1}/>
            </Modal>
        </>

    )
}