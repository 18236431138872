import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
    // Initialize state with token from localStorage (if it exists)
    const initialToken = localStorage.getItem("jwt");
    const [loggedInUser, setLoggedInUser] = useState(() => {
        const storedUser = localStorage.getItem('loggedInUser');
        if (!storedUser || storedUser === "undefined") {
            return null;
        }
        return JSON.parse(storedUser);
    });

    const [token, setToken] = useState(initialToken);

    const isLoggedIn = Boolean(token);

    useEffect(() => {
        // Whenever the token changes, update the localStorage
        if (token) {
            localStorage.setItem("jwt", token);
        } else {
            localStorage.removeItem("jwt");
        }
    }, [token]);

    const login = (jwtToken, firstname, email) => {
        setToken(jwtToken);
        setLoggedInUser({
            "firstname": firstname,
            "email": email
        });

        localStorage.setItem('loggedInUser', JSON.stringify({
            "firstname": firstname,
            "email": email
        }));

    }

    const logout = () => {
        setToken(null);
        setLoggedInUser(null);
        localStorage.removeItem('loggedInUser');
    }

    return (
        <UserContext.Provider value={{ token, isLoggedIn, login, logout, loggedInUser }}>
            {children}
        </UserContext.Provider>
    );
}

