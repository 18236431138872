import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Form, InputGroup, Navbar, Nav, Table, Container, Row, Col } from "react-bootstrap";
import ReactTable from "../../components/ReactTable/ReactTable.js";
import FormModal from "../Components/FormModal.js";


function ChatList() {
  const [data, setData] = useState([]);

  const handleDelete = async (filename) => {
    try {
      const response = await fetch(`https://webex.l1x.foundation/deleterecord?filename=${filename}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      window.location.reload();
    } catch (error) {
      console.error("There was a problem with the delete operation:", error.message);
    }
  };

  useEffect(() => {
    fetch("https://webex.l1x.foundation/getChatList")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((apiData) => {
        const formattedData = apiData.map((prop) => {
          const uploadDate = prop.upload_time.slice(0, 8);
          const formattedUploadDate = `${uploadDate.slice(6, 8)}/${uploadDate.slice(4, 6)}/${uploadDate.slice(0, 4)}`;
          const uploadTime = prop.upload_time.slice(9);
          const formattedUploadTime = `${uploadTime.slice(0, 2)}:${uploadTime.slice(2, 4)}:${uploadTime.slice(4, 6)}`;
          const ageDays = Math.round(prop.age * 10) / 10;

          return {
            id: prop.space_id,
            filename: prop.filename,
            time: `${formattedUploadDate} ${formattedUploadTime}`,
            spaces: prop.space_id,
            age: `${ageDays} days ago`,
            actions: (
              <div className="actions-right">
                <Link
                  to={{
                    pathname: "/admin/chat",
                    search: `?filename=${prop.filename}`
                  }}
                >
                  <Button variant="info" size="sm" className="text-info btn-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </Button>{" "}
                </Link>
                <Button
                  onClick={() => handleDelete(prop.filename)}
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
        });
        setData(formattedData);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error.message);
      });
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" className="float-left">Chat List</Card.Title>
                <FormModal />
              </Card.Header>
              <div className="border mt-3"></div>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Space ID",
                      accessor: "spaces",
                    },
                    {
                      Header: "Upload Time",
                      accessor: "time",
                    },
                    {
                      Header: "Report age",
                      accessor: "age",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChatList;
